<template>
  <Layout>
    <PageHeader :title="title" :items="items" class="poppins fw-normal" />
    <div class="addUser">
      <div class="row">
        <div class="col-lg-6 col-12">
          <div class="card">
            <div class="card-body">
              <form @submit.prevent="updateTheUser">
                <div class="row">
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Firstname-input"
                        >First Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.first_name"
                        id="formrow-Firstname-input"
                        placeholder="Enter Your First Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Lastname-input"
                        >Last Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        v-model="form.last_name"
                        id="formrow-Lastname-input"
                        placeholder="Enter Your Last Name"
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-email-input"
                        >Email</label
                      >
                      <input
                        type="email"
                        v-model="form.email"
                        class="form-control"
                        id="formrow-email-input"
                        placeholder="Enter your email address"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="mb-3">
                      <label class="form-label" for="formrow-Contact-input"
                        >Contact No.</label
                      >
                      <input
                        type="tel"
                        v-model="form.mobile"
                        class="form-control"
                        id="formrow-Contact-input"
                        placeholder="(907) 555-0101"
                      />
                    </div>
                  </div>
                </div>

                <!-- <div class="form-check">
                                                      <input type="checkbox" class="form-check-input" id="formrow-customCheck">
                                                      <label class="form-check-label" for="formrow-customCheck">Check me out</label>
                </div>-->

                <div class="d-flex flex-wrap gap-3 mt-3">
                  <button
                    type="submit"
                    class="btn btn-primary waves-effect waves-light w-md"
                  >
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";

export default {
  page: {
    title: "Update User",
    meta: [
      {
        name: "update-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      form: {
        id: this.$route.params.id,
        first_name: null,
        last_name: null,
        email: null,
        mobile: null,
      },
      user: {},
      title: "Update User",
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Update User",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.singleUserRecord();
  },
  methods: {
    singleUserRecord() {
      this.$axios
        .get("single-user-record/" + this.form.id)
        .then((response) => {
          this.user = response.data.data;
          this.form.first_name = this.user.first_name;
          this.form.last_name = this.user.last_name;
          this.form.email = this.user.email;
          this.form.mobile = this.user.mobile;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    updateTheUser() {
      this.$axios
        .post("update-user-data", this.form)
        .then((response) => {
          this.triggerSwal(response.data.message, "success");
          this.$router.push({
            name: "user-listing",
          });
        })
        .catch((error) => {
          this.handleErrorResponse(error.response, "error");
        });
    },
  },
};
</script>